$(function () {
  let ftBtn = $('.fixed_footer_link');
  let ftFlg = false;
  $(window).on('scroll', function () {
    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    footHeight = $("footer").outerHeight() + 100;
    if ($(this).scrollTop() > 300) {
      if (ftFlg == false) {
        ftBtn.stop().fadeIn(200);
        ftFlg = true;
      }
      if (scrollHeight - scrollPosition <= footHeight) {
        if (ftFlg == true) {
          ftBtn.stop().fadeOut(200);
          ftFlg = false;
        }
      }
    } else {
      if (ftFlg == true) {
        ftBtn.stop().fadeOut(200);
        ftFlg = false;
      }
    };

  });
});
