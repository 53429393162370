var defaultQueryList = {
  mobile: matchMedia("(max-width: 768px"),
  pc: matchMedia("(min-width: 769px)")
}

function routingByBreakpoint(sourceList) {
  for (var i = 0, l = sourceList.length; i < l; i++) {
    var source = sourceList[i];

    if (!(source.dataset.query && defaultQueryList[source.dataset.query] && source.dataset.src)) continue;
    if (!(defaultQueryList[source.dataset.query].matches)) continue;

    var newSource = document.createElement("source");
    newSource.src = source.dataset.src;

    source.parentElement.appendChild(newSource);
  }
}

(function () {
  var elemList = document.getElementsByClassName("routingByBreakpoint");

  for (var i = 0, l = elemList.length; i < l; i++) {
    var sourceList = elemList[i].getElementsByTagName("source");

    routingByBreakpoint(sourceList);
  }

})();
