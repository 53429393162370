import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });


//var flg;
let menuFlg = true;
let ResvFlg = true;
//var moveS = $('.nav').outerWidth();
$(document).on('click', '.menu-btn', function () {
  var $this = $(this);
  //$this.find('.menu-trigger').stop().toggleClass('active');

  if (menuFlg) {
    gsap.to('.nav', {
      autoAlpha: 1,
      duration: 0.3
    });
    //$(this).find('.word').text('CLOSE');
    $(this).addClass('active');
    $('header').addClass('on');
    menuFlg = false;
    if (ResvFlg == false) {
      gsap.to('.ResvNav', {
        autoAlpha: 0,
        duration: 0.3
      });
      //$('.ResvBtn').find('figure').show();
      //$('.ResvBtn').find('.word').html('CONTACT<br>&<br>RESERVATION');
      $('header').find('.insta svg').removeClass('active');
      $('header').find('.tel p').removeClass('active');
      $('header').removeClass('on');
      ResvFlg = true;

    }

  } else {

    gsap.to('.nav', {
      autoAlpha: 0,
      duration: 0.3
    });
    //$(this).find('.word').text('MENU');
    $(this).removeClass('active');
    $('header').removeClass('on');
    menuFlg = true;
  }
  return false;
});
$(document).on('click', '.ResvBtn', function () {
  var $this = $(this);

  if (ResvFlg) {
    gsap.to('.ResvNav', {
      autoAlpha: 1,
      duration: 0.3
    });
    ResvFlg = false;
    //$this.find('figure').hide();
    //$this.find('.word').html('<span>×</span><br>CLOSE');
    $('header').find('.insta svg').addClass('active');
    $('header').find('.tel p').addClass('active');
    if (menuFlg == false) {
      gsap.to('.nav', {
        autoAlpha: 0,
        duration: 0.3
      });
      //$('.menu-btn').find('.word').text('MENU');
      //$('.menu-trigger').stop().toggleClass('active');
      $('.menu-btn').removeClass('active');
      menuFlg = true;
    }

  } else {

    gsap.to('.ResvNav', {
      autoAlpha: 0,
      duration: 0.3
    });
    ResvFlg = true;
    //$this.find('figure').show();
    //$this.find('.word').html('CONTACT<br>&<br>RESERVATION');
    $('header').find('.insta svg').removeClass('active');
    $('header').find('.tel p').removeClass('active');
  }
  return false;
});

