

$(function () {
  var $elem = $('.posSwitch');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.insertAfter(posFlgA);
      } else {
        $this.insertAfter(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

$(function () {
  var $elem2 = $('.posSwitch2');
  var replaceWidth = 1001;
  function posSwitch2() {
    var windowWidth = parseInt($(window).width());
    $elem2.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.prependTo(posFlgB);
      } else {
        $this.prependTo(posFlgA);
      }
    });
  }
  posSwitch2();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch2();
    }, 100);
  });
});

$(function () {
  var $elem3 = $('.posSwitch3');
  var replaceWidth = 1001;
  function posSwitch3() {
    var windowWidth = parseInt($(window).width());
    $elem3.each(function () {
      var $this = $(this).find('.posSwitchTarget2');
      var posFlgC = $(this).find('.posFlgC');
      var posFlgD = $(this).find('.posFlgD');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgC);
      } else {
        $this.insertAfter(posFlgD);

      }
    });
  }
  posSwitch3();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch3();
    }, 100);
  });
});

$(function () {
  var $elem = $('.posSwitch4');
  var replaceWidth = 1001;
  function posSwitch4() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this1 = $(this).find('.posSwitchTarget1');
      var $this2 = $(this).find('.posSwitchTarget2');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      var posFlgC = $(this).find('.posFlgC');
      if (windowWidth >= replaceWidth) {
        $this1.appendTo(posFlgA);
        $this2.appendTo(posFlgB);
      } else {
        $this2.appendTo(posFlgA);
        $this1.insertAfter(posFlgC);
      }
    });
  }
  posSwitch4();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch4();
    }, 100);
  });
});

$(function () {
  var $elem = $('.posSwitch5');
  var replaceWidth = 1001;
  function posSwitch5() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.prependTo(posFlgA);
      } else {
        $this.prependTo(posFlgB);
      }
    });
  }
  posSwitch5();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch5();
    }, 100);
  });
});

$(function () {

  $('.slick-dots').appendTo('.mv');
});

$(function () {
  var $elem = $('.posSwitch6');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.insertAfter(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});
