import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$('.top-chapel-slides').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.top-banquet-slides').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});


// if ((new Date("2023/7/24 14:53:00").getTime()) > (new Date().getTime())) {
// } else {
//   $('.top-banquet-slides').slick('slickRemove', 3, false);
// }



$('.top-othercontents-slides').slick({
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: true,
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,

      }
    }
  ]
});

$('.ceremony-outline-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1000, // 399px以下のサイズに適用
      settings: {

        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,

      }
    }
  ]

});

$('.othercontents-slides').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: false
      }
    }
  ]
});

$('.party-familiesparty-slides').slick({
  dots: true,
  fade: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.cuisine-gallery-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.dress-dresscontent-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.item-media-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.about-location-slides').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.about-staff-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      variableWidth: true,
      centerMode: false,
      infinite: false
    }
  }]
});

$('.about-contents-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      variableWidth: true,
      centerMode: false,
      infinite: false
    }
  }]
});

$('.facility-gallery-slide').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1001,
      settings: {
        centerMode: true,
        centerPadding: '25px',
      },
    },
  ],
});

$('.story-story-slides').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,

});

$('.small-wedding-reason-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});

$('.small-wedding-villa-slides').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
});

$('.commonreport-slide').slick({
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [{
    breakpoint: 1000,
    settings: {
      dots: true,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      centerMode: true
    }
  }]
});

$('.photo-outline-slide').slick({
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  centerMode: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  responsive: [
    {
      breakpoint: 1001,
      settings: {
        centerPadding: '25px',
      },
    },
  ],
});

$('.clover-outline-slide').slick({
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false
});
