$(function () {
  var $tab = $('.p-faq .selectSec .item');
  $tab.on('click', function () {
    var index = $(this).index();
    var nth = index + 1;
    $tab.removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(' + nth + ')').addClass('is-active');
  });
  $('.p-faq .selectSec .item a[href*="#"]').on('click', function () {
    var elmHash = $(this).attr('href'); //ページ内リンクのHTMLタグhrefから、リンクされているエリアidの値を取得
    var minus = $('header').height();
    var pos = $(elmHash).offset().top - minus;	//idの上部の距離を取得
    $('body,html').animate({ scrollTop: pos }, 600); //取得した位置にスクロール。500の数値が大きくなるほどゆっくりスクロール
    //return false;
  });
  hash = $(location).attr('hash');
  if (hash == '#attendance') {
    $('.p-faq .selectSec .item').removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(2)').addClass('is-active');
  } else if (hash == '#other') {
    $('.p-faq .selectSec .item').removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(3)').addClass('is-active');
  } else if (hash == '#preview') {
    $('.p-faq .selectSec .item').removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(1)').addClass('is-active');
  }
})

$(function () {
  $('.report-tab').find('ul li span').on('click', function () {
    $(this).next().stop().slideToggle(200);
  });
});
