
if (window.document.body.id === 'p-about' || window.document.body.id === 'p-story') {
  let head = $('header');
  let headFlg = false;
  $(window).on('scroll', function () {
    let mvHeight = $('.mvSec').height();
    let headerHeight = head.height() / 2;
    if ($(this).scrollTop() > mvHeight - headerHeight) {
      if (headFlg == false) {
        head.removeClass('is-mv');
        headFlg = true;
      }

    } else {
      if (headFlg == true) {
        head.addClass('is-mv');
        headFlg = false;
      }
    };

  });
}

if (window.document.body.id === 'p-top') {
  let head = $('header');
  let headFlg = false;
  $(window).on('scroll', function () {
    let mvHeight = $('.mvSec').height();
    let headerHeight = head.height() / 2;
    if ($(this).scrollTop() > mvHeight - headerHeight) {
      if (headFlg == false) {
        head.removeClass('is-mv');
        head.removeClass('header-hidden');
        headFlg = true;
      }

    } else {
      if (headFlg == true) {
        head.addClass('is-mv');
        head.addClass('header-hidden');
        headFlg = false;
      }
    };

  });
}


