if (window.document.body.id === 'p-top') {
  var videoElem = document.getElementById('video');

  videoElem.addEventListener('playing', function () {
    $('.mvSec').find('.title').addClass('start');

  });
  // $(window).on('load', function () {
  //   $('.mvSec').find('.title').addClass('start');
  //});
};
